import settings from './settings.js';
import vidneo from './core.js';


import eventHandler from './services/eventHandler.js';
import storage from './services/storage.js';
import Oauth from './services/Oauth.js';
import Epg from './services/Epg.js';
import {Logger} from './services/Logger.js';
import Helpers from './services/Helpers.js';

import {bcplayerComponent} from './components/bcplayerComponent.js';
import { gatewayComponent} from './components/gatewayComponent.js';
import { modalComponent} from './components/modalComponent.js';
import { emissionComponent} from './components/emissionComponent.js';
import { noProgramComponent} from './components/noProgramComponent.js';
import { spinnerComponent} from './components/spinnerComponent.js';


import axios from 'axios'
import {I18n} from "i18n-js";
import cat from './translations/cat.json';


import Toastify from 'toastify-js'

const URL_SETTINGS =   'https://d1tsa7ywxx9hjv.cloudfront.net/integration-tv'; 

import './styles/main.scss';
import $, {
  get
} from 'jquery';

window.$vneo = $;

var gatewayPostMessage;
const i18n = new I18n({
  ...cat
});

//container injection for app;
const CI = {version: '2.00', analytics: {mediaCount: 0}};
CI.storage = storage;
window.vneoCI = CI;


CI.logger = new Logger(5, '[vneo]');


i18n.defaultLocale = "cat";
i18n.locale = "cat";
CI.i18n = i18n;

function generarTokenAleatorio() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = Math.random() * 16 | 0;
    const v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

function getOrCreateDid (){
  let did = CI.storage.getCookie('vneo-did');
  if(!did){
    did = generarTokenAleatorio();
    CI.storage.setCookie('vneo-did',did, 60*60*24*365);
  }
  return did;
}

function getOrCreateSid(forceToCreate = false) {
  let sid = CI.storage.getCookie('vneo-sid');
  if(!sid || forceToCreate){
    sid = generarTokenAleatorio() + '_' + Date.now();
    CI.storage.setCookie('vneo-sid',sid);
  }
  return sid;
}


CI.helpers = new Helpers({CI});

CI.eventHandler = eventHandler;
eventHandler.register('onerror', (data) => {});
eventHandler.register('loaded', async (e) => {


});
eventHandler.register('onEpgUpdated', (data) => {});
eventHandler.register('onChallengerCodeRecived', (data) => {});
eventHandler.register('onSessionTokensRecived', (data) => {});
eventHandler.register('onProgram', (data) => {});
eventHandler.register('signin', (data) => {
  CI.logger.log('signin', data);
});

//a tick event is triggered every 60 seconds
eventHandler.register('tick', (e) => {
  CI.logger.log('tick');
});


//didomiReady
eventHandler.register('didomiReady', (data) => {
     CI.logger.log('didomiReady', data);
     CI.didomiInfo = {...data.payload};
    // CI.eventHandler.notification('userLogin', {...data.payload});
});



eventHandler.on('ready', async (data) => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    var show = 'emission';
    
   
    CI.logger.log('tokenData',tokenData);

    if(tokenData){
       
        CI.helpers.setSession(tokenData);
    }


    if(code){
      let result = await  CI.oauth.getAccessToken(code);
      if(result){
        CI.storage.setItem('vneo-token-data', result.payload.tokenData);
        CI.helpers.setSession(result.payload.tokenData);
        CI.helpers.removeParamFromURl('code');
        

        var didomiData = result.payload.tokenData.DidomiData;

        CI.helpers.getUser().then((data) => {
            CI.user = data;
            CI.eventHandler.sendPostMessage('gateway', {
              code: 'didomiSetup',
              payload: {didomiData}
            }, '*');
        });
        show = 'player';
      }
    }else {
        
      var tokenData = CI.storage.getItem ('vneo-token-data');
      if(tokenData) CI.helpers.setSession(tokenData);
    }

    //gateway ready
    CI.helpers.requestEpg({show});
});

eventHandler.on('refreshedToken', async (data) => {
  console.log('refreshedToken', data);
  var tokenData = CI.storage.getItem ('vneo-token-data');
  tokenData.AuthorizationToken = data.payload.AuthorizationToken;
  CI.storage.setItem('vneo-token-data', tokenData);
});






eventHandler.on('getEpgResponse', async (data) => {
  CI.logger.log('getEpgResponse', data.payload);
  CI.epg = new Epg(data.payload.entity);
  let current = CI.epg.getCurrent();



  let show = (data.payload.callback && data.payload.callback.show)?   data.payload.callback.show : 'emission';
  CI.logger.log('current',current);

  if(!current){
    CI.logger.error('current program not found');
    show = 'noProgram';
   
  }


  CI.helpers.clearScreen();


   switch (show) {
    case 'player':
      CI.bcplayer.parse(current);
      break;
    case 'noProgram':
      CI.noprogram.parse();
      break;
    default:
      CI.emission.parse(current);
      break;
   }



});

eventHandler.on('getMediaResponse', async (data) => {
    let program = CI.epg.parseProgram(data.payload.entity);
    if(!program){
      return;
    }
  
    if(program.start > Date.now()) {
      CI.logger.error('program not started yet');
      return
    }
    CI.helpers.clearScreen();
    CI.spinner.show();
    let currentMetadata = {...program, type :"PROGRAM"};
    CI.helpers.playOrAuthorize(currentMetadata);;
});

eventHandler.on('epgProgram', async (data) => {
   CI.logger.log('epgProgram data', data);
   var programId = data.payload.programId;
   CI.helpers.requestMedia(programId);
});




eventHandler.on('playLive', async (data) => {
  let currentMetadata = {...CI.epg.getCurrent(), type :"CHANNEL"}; 
  CI.helpers.playOrAuthorize(currentMetadata);

});


eventHandler.on('logout', async (data) => {
  CI.helpers.requestCustom('logout');
  settings.did = getOrCreateDid();
  settings.sid = getOrCreateSid(true);
  CI.bcplayer.remove();
  
  CI.storage.deleteAll();
  CI.helpers.requestEpg({show:'emission'});

});


$vneo(document).on('click', '.PLAY' ,(e) => {
    e.preventDefault();
    CI.eventHandler.trigger('playLive', {});
    
});


window.addEventListener("load", async function () {
  try {
    
    const apiUrl = URL_SETTINGS ; 
    const rootId = '#vneo_app';

    //se('$vneo', '3.7.1', 'sha256-/JqT3SQfawRcv/BIHPThkBvs0OEvtFFmqPF/lYI/Cxo=');

    $vneo(rootId).append('<div class="vneo-container"></div>');
    const parentSelector = '#vneo_app > .vneo-container';

    //get settings
    const tvId = $vneo('#vneo_app').data('id');
    const settings = (await axios.get(`${apiUrl}/tv/${tvId}/settings`, {
      responseType: 'json'
    })).data.payload;
    settings.iframeId = 'vneo_gateway';

    settings.did = getOrCreateDid();
    settings.sid = getOrCreateSid(true);
    

    CI.settings = settings;
    CI.sid = generarTokenAleatorio();

    CI.oauth = new Oauth({
      ci: CI,
      oauthApi: settings.oauthApi,
      clientId: settings.bsgCanalId,
    });

    //gateway -- llamadas a la api de bsg
    const gateway = gatewayComponent(vidneo, {
      url: document.location.href,
      parentSelector,
      id: settings.iframeId,
      src: `${settings.gatewaySrc}`,
    }, CI);
    let iframeId = gateway.parse();
    CI.eventHandler.registerIframe('gateway', document.getElementById(iframeId));

    //spinner 
    const spinner = spinnerComponent(vidneo ,{
      parentSelector,
      CI
    });
    spinner.parse();
    CI.spinner = spinner;

    //Modal
    const modal = modalComponent(vidneo, {
      parentSelector
    });

    modal.parse();
    CI.modal = modal;

    //Program
    const emission = emissionComponent(vidneo, {
      parentSelector,
      CI
    });
    CI.emission = emission;


    //NoProgram
    const noprogram = noProgramComponent(vidneo, {
      parentSelector,
      CI
    });
    CI.noprogram = noprogram;

    //BCPlayer
    const bcplayer = bcplayerComponent(vidneo, {
      parentSelector,
      CI
    });

    CI.bcplayer = bcplayer;
    CI.eventHandler.trigger('loaded', {});

  } catch (error) {
    CI.logger.error(error);
  }

  //no debe haber nada a partir de aqui

}, false);


function toast(message) {
  Toastify({
    className: "vneo-toast",
    text: message,
    duration: 3000,

    close: false,
    gravity: "top", // `top` or `bottom`
    position: "right", // `left`, `center` or ``
    stopOnFocus: true, // Prevents dismissing of toast on hover

  }).showToast();
}