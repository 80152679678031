var noProgramComponent = function (vidneo, settings) {

    var selector = settings.parentSelector;
    const id = 'nopr_' + (new Date()).getTime();
    var CI = settings.CI;
    var currentProgram = null;

    const template = vidneo.tmpl(`
     
            <div id=<%= this.noprId %> class="program">
            <div class="row flex">
                <!-- Columna B (arriba en mobile, derecha en desktop) -->
                <div class="col flex-col thumbnail vneo_no-padding"
                     style="background-image:url(<%= this.image %>)" >
                  
                        <div class="cortina" ></div>
                    
                </div>

                <!-- Columna A (abajo en mobile,  en desktop) -->
                <div class="col flex-col vneo_no-padding">
                <div class="card-panel program-description black white-text">
                   </span>  
                    <h4 ><%= this.title %></h4>
                   
                    <% if(this.description) { %>
                        <%= this.description %>
                    <% } else { %>
                         <div class="vneo-spacer"> </div>
                    <% }  %>
                   
                </div>
                </div>
            </div>
        </div>
        
    `);


    var setComponent = (params = {}) => {
        var image = params.image || CI.settings.assets_cdn +'/img/tvmataro-login.jpg';
        var title = params.title || 'Hi ha hagut un error';
        var description = params.description ||'No es pot reproduir el directe';

        $vneo(selector).append(
            template({
                title ,
                description ,
                noprId: id,
                image
            })
        );

        return id;
    };

    function remove() {
        $vneo('#' + id).remove();
        
    }


 



    return {
        id: () => id,
        parse: () => setComponent(),
        remove: () => remove(),
       

    }
}


export {
    noProgramComponent
};
