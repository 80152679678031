

var  spinnerComponent = function (vidneo, settings) {

    var selector = settings.parentSelector;
    const id = 'sp_' + (new Date()).getTime();
    var CI = settings.CI;
        
    const template = vidneo.tmpl( `
        <div id=<%= this.spId %> class="vneo vneo-spinner row " >
           <div class="center-align col s12 offset-s5">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="75" height="75"><circle cx="50" cy="50" r="45" fill="none" stroke="#EEEEEE" stroke-width="10" stroke-dasharray="118 47" stroke-opacity="0.8"><animateTransform attributeName="transform" type="rotate" dur="1s" from="0 50 50" to="360 50 50" repeatCount="indefinite"></animateTransform></circle></svg>
            </div>
        </div>
    `);
    
    
    var setComponent = () => {
        $vneo(selector).append(
            template({
                spId: id
            })
        );
        return id;

    };        
    
    
    return {
        parse : () => setComponent(),
        show : () => $vneo('#'+id).show(),
        hide:  () =>  $vneo('#'+id).hide(),
       
        
    }
}


export {spinnerComponent};