var emissionComponent = function (vidneo, settings) {

    var selector = settings.parentSelector;
    const id = 'pr_' + (new Date()).getTime();
    var CI = settings.CI;
    var currentProgram = null;

    const template = vidneo.tmpl(`
     
            <div id=<%= this.prId %> class="program">
            <div class="row flex">
                <!-- Columna B (arriba en mobile, derecha en desktop) -->
                <div class="col flex-col thumbnail vneo_no-padding"
                     style="background-image:url(<%= this.image %>)" >
                  
                        <div class="cortina" ></div>
                    
                </div>

                <!-- Columna A (abajo en mobile,  en desktop) -->
                <div class="col flex-col vneo_no-padding">
                <div class="card-panel program-description black white-text">
                   </span>  <span class="white-text">🔴 ${CI.i18n.t('live').toUpperCase()}</span>
                    <h4 ><%= this.title %></h4>
                   
                    <% if(this.description) { %>
                        <%= this.description %>
                    <% } else { %>
                         <div class="vneo-spacer"> </div>
                    <% }  %>
                    <div class="row">
                        <div class="col s12" >
                            <div class="progress" >
                                <div class="determinate orange" style="width: <%= this.offsetPercent %>%"></div>
                            </div>
                             <div class="row">
                                <div class="col s6 left-align">
                                    <p><%= this.startHuman %>h</p>
                                </div>
                                
                                <div class="col s6  right-align">
                                    <p><%= this.endHuman %>h</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col s12 vneo-spacer"  ></div>
                        <div class="col s12 " >
                          <a href="javascr6ipt:void(0)" class="PLAY pulse orange btn">${CI.i18n.t('watch_live')}</a>
                        </div>

                    </div>
                </div>
                </div>
            </div>
        </div>
        
    `);


    var setComponent = (program) => {
        CI.spinner.hide();
        currentProgram = program;
        //image HIGHLIGHTS,FRAME
        $vneo('#' + id).remove();
        var image = CI.helpers.getProgramImage(program);

        $vneo(selector).append(
            template({
                ...program,
                prId: id,
                image
            })
        );

        return id;
    };


    function updatePercet() {
        let now = new Date();
        let program = currentProgram;
        let element = $vneo('#' + id + ' .determinate')[0] || null;
        if (element) {
            let offsetPercent = parseInt((now.getTime() - program.start.getTime()) / (program.duration) * 100);
            element.style.width = offsetPercent + '%';
            CI.logger.log('offsetPercent', '#' + id + ' .progress .determinate', offsetPercent);
        }
    }

    function remove() {
        $vneo('#' + id).remove();
        CI.eventHandler.remove(eventId);
    }


    let eventId = CI.eventHandler.register('tick', (e) => {
        let now = new Date();
        let program = currentProgram;
        if (!program) return;

        let current = program.start <= now && program.end >= now;

        if (current) {
            updatePercet();
        }

        if (!current) {
            CI.helpers.requestEpg();

        }
    });



    return {
        id: () => id,
        parse: (program) => setComponent(program),
        remove: () => remove(),
        current: () => currentProgram

    }
}


export {
    emissionComponent
};