class Epg {

    #programs = [];
    constructor(entity){
        if(typeof entity !== 'undefined'){
            this.setEntity(entity);
        }
    }

    setEntity(entity){
        this.#programs = entity.Media.map((media) => {
            return this.parseProgram(media);
        });
    }

    parseProgram(media){
        let tmp =  {
            id: media.Id,
            title: media.Title,
            description: media.Description,
            start: this.parseDate(media.StartDateTime	),
            end: this.parseDate(media.EndDateTime),
            
            images: media.Images,
            canPlay : media.StreamingPermission,
            url: media.Url,
            type: media.MediaTypeCode,
            typeName : media.MediaTypeDisplayName		
        };
        tmp.duration = (tmp.end.getTime() - tmp.start.getTime());
        tmp.durationHuman = this.milisecondsToHumanReadable(tmp.duration);
        tmp.startHuman = this.hourToHumanReadable(tmp.start);
        tmp.endHuman = this.hourToHumanReadable(tmp.end);
        return tmp;

    } 

    getCurrent(miliseconds = null){
        let now = (!!miliseconds)?  new Date(miliseconds) :  new Date();
        let current =  this.#programs.filter((program) => {
            return program.start <= now && program.end >= now;
        })[0] || null; 
        
        if(!current) return null;
        
        current.offsetPercent = parseInt((now.getTime() - current.start.getTime()) / (current.duration) * 100);
        return current;
    }

  

    hourToHumanReadable(date){
        let hours = date.getHours();
        let minutes = date.getMinutes();
        if(minutes < 10){
            minutes = `0${minutes}`;
        }
        return `${hours}:${minutes}`;
    };

    milisecondsToHumanReadable(time){
        let seconds = time / 1000;
        let hours = Math.floor(seconds / 3600);
        seconds = seconds % 3600;
        let minutes = Math.floor(seconds / 60);
        seconds = seconds % 60;

        let display = '';
        if(hours > 0){
            display += `${hours}h `;
        }
        if(minutes < 10 ){
            minutes = `0${minutes}`;
        }
        display += `${minutes}m`;

        return display
        
    }
    

    //function to transform string date 2024-10-30T22:30:00.0000000+00:00 to date object
    parseDate(date){
        return new Date(date);
    }

}


export default Epg;