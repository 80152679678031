
import $ from 'jquery';
import Konodrac from '../services/Konodrac';

var  bcplayerComponent = function (vidneo, params) {

    var player = null;
    var componentId = 'vneo-player_' + (new Date()).getTime() + '_' + Math.floor(Math.random() * 1000);
    var CI = params.CI;
    const  selector = params.parentSelector || '#vneo_container';
    const settings = {...CI.settings};
    var id  = 'bc_'+(new Date()).getTime();
    var _program = null;
    var _konodrac = null;

    var tags = {
        'canplay': 3,   //start
        'play': 4,
        'pause': 5,
        'ended': 6,
        'dispose':7,
        'seeking': 9, //seek
        'error': 11,
        'loadeddata': 12,
        'fullscreen': 14,
        'volumechange': 15,
    };
    



    var eventHandlersIds  = [];
        
    const templateVideo = vidneo.tmpl( `
        <div class="vneo_aspect-ratio-16-9 vneo_player" id="<%= this.id %>_container" >
            <video-js class="vneo_video" id="<%= this.id %>"
                data-video-id= "<%= this.videoId %>"
                data-account="<%= this.account %>"
                data-player="<%= this.playerId %>"
                data-embed="default"
                controls=""
                data-application-id=""
                poster ="<%= this.image %>"
                class="video-js vjs-default-skin">
            </video-js>
        </div>
    `);

    function destroy(){
        if(!player) return;
        _konodrac.sendMark({tag:7});
        player.dispose();
        $vneo('#'+id +'_container').remove();
        player = null;
        if(typeof window.player !== 'undefined') player = null;
        
        
        window.videojs = null;
       
       
        vidneo.remove('pluginTv');
        vidneo.remove('brightcove');

        eventHandlersIds.forEach((id) => {
            CI.eventHandler.remove(id);
        });
        eventHandlersIds = [];
        _program = null;
        _konodrac = null;
       
    }


    function sendMarkToKonodrac(options){
        if(!_konodrac) return;
        if(!CI.user) return;
        try {
            _konodrac.sendMark(options);
        } catch (error) {
            CI.logger.error('sendMarkToKonodrac',error);
        }
    }


    function loadBrightcove(selector,settings,program) {
        if(player) destroy();
        CI.logger.log('setComponent',program);
       _program = program;
        
        id = 'bc_'+(new Date()).getTime();
        let image = CI.helpers.getProgramImage( program) ;
        CI.logger.log('program >>>',program, _program);

        CI.analytics.mediaCount ++;

        var videoSettings = {...settings, id, image} ;
        $(selector).append(templateVideo(videoSettings));
        
        const videoUrl = `https://players.brightcove.net/${settings.account}/${settings.playerId}_default/index.min.js`;
        const pluginUrl = CI.settings.assets_cdn + "/dist/videojs-tv.js";

        vidneo.loadCss('cssTv', CI.settings.assets_cdn +  '/dist/videojs-tv.css');
        vidneo.require('brightcove', videoUrl, () => {
            videojs.use('*', function (player) {
                return {
                  setSource: function (srcObj, next) {
                   
                    
                    let currentMetaData = _program;
                    
                    //fix double ?
                    let newObject = { ...srcObj };
                  
                    let parts = newObject.src.split('?');
                    let base = parts.shift();
        
                    newObject.src = (parts.length > 0) ? base + '?' + parts.join('&') : base;
        
                    newObject.src = CI.helpers.setUrlParameter(newObject.src, 'vfnd', 1);

                    console.log('newObject.src',newObject.src);
                    
                    if (newObject.src.indexOf('cloudfront.net/short') !== -1) {
        
                      let _url = (!window.tizen && !window.webOS) ? newObject.src : newObject.src.replace('/short', '/short-tv');
                      
                      let request = new XMLHttpRequest();
                      request.open("GET", _url, false); // `false` makes the request synchronous
                      request.send();
        
                      newObject.src = (!window.tizen && !window.webOS) ? request.responseURL : request.response.trim();
                    }
        
                    if(currentMetaData.type === 'PROGRAM'){
                      let now = new Date();
                      
                      if(now > currentMetaData.start)  newObject.src = CI.helpers.setUrlParameter(newObject.src, 'start', parseInt (currentMetaData.start.getTime() / 1000));
                      if(now > currentMetaData.end)    newObject.src = CI.helpers.setUrlParameter(newObject.src, 'end',   parseInt (currentMetaData.end.getTime()   / 1000));
                    }
        
                    CI.logger.debug('canal url', newObject.src,currentMetaData);
                    next(null, newObject);
                  }
                };
              });

            let eventOnplayer = CI.eventHandler.register('onplayer', (data) => {
                if(!_konodrac) return;
                let event = data.event;
                if(typeof tags[event] === 'undefined') return;

                if (event === 'volumechange' && !player.muted()) return;
                
                console.log('onplayer mark',data.event, );
                sendMarkToKonodrac({tag: tags[event]});
            });
            eventHandlersIds.push(eventOnplayer);

            let eventTick = CI.eventHandler.register('tick', (data) => {
                sendMarkToKonodrac({tag:8});
                sendMarkToKonodrac({tag:2});
                
            });

            eventHandlersIds.push(eventTick);

            vidneo.require('pluginTv', pluginUrl, () => {
                player = videojs(id,{}, function() {});
                var xalPlugin =  player.xalTv({CI: CI});
                CI.xalPlugin = xalPlugin;
                _konodrac = new Konodrac({CI,player, program: program});
                CI.eventHandler.registerPlayerEnvents(player);
                
               player.play();
            });

            
     
        });
    }

    //fucntion to overwrite url params or add it 
    function _setUrlParameter(url, key, value) {
        var url = new URL(url);
        url.searchParams.set(key, value);
        return url.href;
    }

    function changeSource(start, end) {
        var url = player.src();
        url = _setUrlParameter(url, 'start', start);
        url = _setUrlParameter(url, 'end', end);
        player.src(url);
    }
    
    
    var setComponent = (program) => {
        CI.spinner.hide();
       loadBrightcove(selector,settings,program);
    };     
    
    
    function init(){
        
    }
    init();
    
    return {
        parse : (program) => setComponent(program),
        setSrc : (src) => {
            player.src(src);
        },
        setProgram(epgProgram) {
            var start = parseInt (epgProgram.start.getTime()/ 1000);
            var end   = parseInt (epgProgram.end.getTime()/ 1000);
            changeSource(start, end);
        },
        getPlayer : () => player,
        remove : () => destroy(),
        getProgram : () => _program,
        getKonodrac : () => _konodrac,
               
    }
}


export {bcplayerComponent};