
class Helpers {

    #CI = null;

    constructor(params) {
        this.#CI = params.CI;
        
    }

    setUrlParameter(url, key, value) {
      var newUrl = new URL(url);
      newUrl.searchParams.set(key, value);
      return newUrl.href;
    }

    unsetUrlParameter(url,key){
      var newUrl = new URL(url);
      newUrl.searchParams.delete(key);
      return newUrl.href;
    }

    removeParamFromURl( param) {
      const url = new URL(window.location.href);
      url.searchParams.delete(param);
      window.history.replaceState({}, '', url);
    }

    requestMedia (id)  {
        this.#CI.eventHandler.sendPostMessage('gateway', {
          code: 'getMedia',
          payload: {
            mediaId: id
          }
        }, '*');
    }

    requestSendMark (mark)  {
        this.#CI.eventHandler.sendPostMessage('gateway', {
          code: 'sendMark',
          payload: mark
        }, '*');
    }

    
    requestEpg (callback = 'emission')  {
          const channelId = this.#CI.settings.bsgCanalId;
          this.#CI.eventHandler.sendPostMessage('gateway', {
            code: 'getEpg',
            payload: {
              channelId,
              callback
            }
          }, '*');
    }

    requestCustom(name, payload)  {
        this.#CI.eventHandler.sendPostMessage('gateway', {
          code: name,
          payload
        }, '*');
    }

    setDidomi (didomiData)  {
      if(typeof this.#CI.didomiInfo === 'undefined'){
        this.#CI.didomiInfo = {};
      }
      this.#CI.logger.log('setDidomi helper', didomiData);
      this.#CI.didomiInfo = {...this.#CI.didomiInfo, ...didomiData};
    }
    
    setSession (tokenData)  {  
        var user = tokenData.User;
        this.#CI.eventHandler.notification('userLogin', {user});
        this.setDidomi({tcString: tokenData.tcString});
        this.#CI.eventHandler.sendPostMessage('gateway', {
          code: 'setSession',
          payload: {
            tokenData
          }
        }, '*');
    }

    getProgramImage (program)  {
        var image = null;
        if(!program || !program.images){
            return null;
        }
        //program.images.foreach(); 
        Object.keys(program.images).forEach((key) => {
            
            if(program.images[key].ImageTypeCode === 'HIGHLIGHTS'){
                image = program.images[key].Url;
            }
  
            if(program.images[key].ImageTypeCode === 'FRAME' && !image){
                image = program.images[key].Url;
            }
        });
  
        return image;
    }

    getUser ()  {
      return new Promise((resolve, reject) => {
          let localstorageData = this.#CI.storage.getItem('vneo-token-data');
          if(localstorageData){
              this.#CI.logger.log('getUser localstorage', localstorageData);
              return resolve(localstorageData.User);
              
          }

          var eventId = this.#CI.eventHandler.register('getUserResponse', (data) => {
              this.#CI.eventHandler.remove(eventId);
              if(!data.payload.user || data.payload.user.Id < 1){
                  reject();
              }
              resolve(data.payload.user);

          });
          

          this.#CI.eventHandler.sendPostMessage('gateway', {
            code: 'getUser',
            payload: {}
          }, '*');


          setTimeout(() => {
            this.#CI.eventHandler.remove(eventId);
           // reject();
          }, 1000);
      });
    }


    clearScreen()  {
      this.#CI.emission.remove();
      this.#CI.noprogram.remove();
      this.#CI.bcplayer.remove();
      this.#CI.spinner.hide();
    }


    playOrAuthorize (currentMetadata)  {
      let CI = this.#CI;
      
      CI.helpers.getUser().then((data) => {
        try {
          CI.user = data;
          this.#CI.eventHandler.sendPostMessage('gateway', {
            code: 'didomiSetup',
            payload: {}
          }, '*');
          CI.emission.remove();
          CI.noprogram.remove();
          CI.bcplayer.parse(currentMetadata);
          
        } catch (error) {
          this.#CI.logger.error('playOrAuthorize', error);
        }
        
      }).catch((error) => {
        this.#CI.logger.log('playOrAuthorize not allow', error);
        CI.oauth.requestAccessCode();
      });
    }

    getApiVersion ()  {
      var data = this.#CI.storage.getItem ('vneo-token-data') || {};
      return data.Version || 0;

    }

    

}

//funccion que recibe una instancia de videojs y retorna las estadisticas : pause or play, duracion, tiempo de reproduccion,etc
// 





  

export default Helpers;