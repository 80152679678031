


class EventHandlerSingleton {


    #eventHandlerName = 'vneoEvent';
    #elementEventHandler = document;
    #handlers = {};
    #iframes = {};
    #tickTImer = null;
    #tickInterval = 50000; //50 segundos para que coincida con el tiempo de la api de konodrac

    constructor() {
      if (EventHandlerSingleton.instance) {
        return Singleton.instance;
        
      }
      
      // Guardar la instancia
      EventHandlerSingleton.instance = this;
      
      this._handleEvents();
      this.#tickTImer = setInterval(() => {
        this.trigger('tick', {});
      }, this.#tickInterval)
  
      // Retornar la instancia creada
      return this;
    }

    uniqId (type = 'default') {
        return type + '_' + Date.now() + Math.round(Math.random() * 100);
    };
  
    trigger (eventType, data, target)  {
       
        target = target || this.#elementEventHandler;
        data = data || {};
        data.type = eventType;
        data.event = data.event || data.key || data.name || eventType;
        
        const event = new CustomEvent(this.#eventHandlerName, { detail: data });
        target.dispatchEvent(event);
       
    };
    
    notification (type, payload){
        payload = payload || {};
        const event = new CustomEvent('vneoNotification', { detail: {type, payload}});
        document.dispatchEvent(event);
        
    }


    registerIframe (name, element) {
        this.#iframes[name] = element;
    }

    sendPostMessage (name, message, origin='*') {
        if(typeof this.#iframes[name] === 'undefined') return;
        this.#iframes[name].contentWindow.postMessage(message, origin);
    }


    registerPlayerEnvents = (player, capturePlayerEvents  ) => {
        //transformamos los eventos en un player en eventos de la clase
        var self = this;
        capturePlayerEvents = capturePlayerEvents || [
            'loadstart',
            'progress',
            'suspend',
            'abort',
            'error',
            'emptied',
            'stalled',
            'loadedmetadata',
            'loadeddata',
            'canplay',
            'canplaythrough',
            'playing',
            'waiting',
            'seeking',
            'seeked',
            'ended',
            'durationchange',
            'timeupdate',
            'play',
            'pause',
            'ratechange',
            'resize',
            'volumechange',
          ];

        for (let i of capturePlayerEvents) {
            player.on(i, function (e) {
                self.trigger('onplayer', { event: i, data: e ,player});
            });
        }
    };

    register (type, callback) {
        if(typeof callback !== 'function'|| typeof type !== 'string') return ;
        
        if(typeof this.#handlers[type] === 'undefined') this.#handlers[type] = [];
        const id = this.uniqId();
        this.#handlers[type].push({id, callback});
        return id;
    }

    on(type, callback) {
        return this.register(type, callback);
    }

    remove (id) {
        for (const type in this.#handlers) {
            this.#handlers[type] = this.#handlers[type].filter(handler => handler.id !== id);
        }
    }



    _handleEvents (){
        var self = this;
        this.#elementEventHandler.addEventListener(this.#eventHandlerName, (e) => {
            //console.log('eventHandler', e);
            const data = e.detail;
            const code = data.type || data.code || '';
            if(typeof self.#handlers[code] === 'undefined' || self.#handlers[code].lenght === 0) return;

            self.#handlers[code].map(handler => {
                handler.callback(data);
            });
        });
    }

    getHandlers () {
        return this.#handlers;
    }
  }

  const eventHandler = new EventHandlerSingleton();

  //convert postMessage to eventHandler
  window.addEventListener('message', (event) => {
    // Validate the origin of the message
    //if (event.origin !== 'https://your-domain.com') return;
  
        try {
            const message = JSON.parse(event.data);
            if(typeof message.code === 'undefined') return
            eventHandler.trigger(message.code, {payload:message.payload});

        } catch (error) {
            eventHandler.trigger('error', {error});
            
        }
    });
  
  // Exportar directamente una instancia única

  export default eventHandler;