

class StorageSingleton {

    #ApiWildDomain = 'localhost';

    constructor() {
      if (StorageSingleton.instance) {
        return StorageSingleton.instance;
      }
      StorageSingleton.instance = this;
      this.#ApiWildDomain = document.location.hostname;
      return this;
    }


    //save localstorage item
    setItem (name, value) {
      localStorage.setItem(name, JSON.stringify(value));
    }

    //get localstorage item
    getItem(name) {
      var item = localStorage.getItem(name);
      if(!item) return null;
      return JSON.parse(item);
    }

    removeItem(name) {
        localStorage.removeItem(name);
    }

    deleteCookie  (e){
        this.setCookie(e,'',-1000 );
    }

    deleteAll() {
        document.cookie.split(";").forEach(cookie => {
          const [name] = cookie.split("=");
          document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
        });
    
        localStorage.clear();
    }
    

    setCookie (name, value, expiresInSeconds , domain, path= '/') {
        var e = name;
        var t = value;
        var i = (expiresInSeconds)? expiresInSeconds * 1000 : null;
        var o = new Date;
        domain = domain || this.#ApiWildDomain;
        if(i) o.setTime(o.getTime() + i);
        var a = o.toUTCString();
        document.cookie = e + " = " + encodeURIComponent(t) + (null == i ? "" : ";SameSite=None;  expires = " + a) + ";Secure; domain="+ domain +";path=/";
    }

    getCookie (e) {
        var t, o, a = null,
            n = document.cookie.split(";");
        for (let i = 0; i < n.length; i++) o = n[i].substring(n[i].indexOf("=") + 1), t = n[i].substring(0, n[i].indexOf("=")).replace(/^\s+|\s+$/g, ""), t == e && (a = unescape(o));
        return a;
    }
    

}


const storage = new StorageSingleton();
export default storage;
      