

var  gatewayComponent = function (vidneo, params,CI) {
        
    const handler = params.handler || function(){};

    var getOrigin = (url) => {
        return (new URL(url)).origin;
    };

    const iframeTmpl = vidneo.tmpl( `
        <iframe id="<%= this.id %>" src="<%= this.src %>" style="width:100%; height:200px; border:1px solid yellow" class="vneo_iframe vneo_unseen">       
        </div>
    `);
    
    var setComponent = () => {
        var id = params.id || 'vneo_gateway';
        const iframe = iframeTmpl({
            id,
            src: params.src
        });
        $vneo(params.parentSelector).append(iframe);
        return id;
        
    };        
    
    
    return {
        parse : () => setComponent(),
        
    }
}


export {gatewayComponent};