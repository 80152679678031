import { isNumeric } from "jquery";

var setMark = (data) => { 
  //alert(data.mediaDuration);
  return  {
    "datasetid": `${data.datasetid}`, 
    "mark": {
      "tag": data.tag,
      "cdata": {
        "digitalData": {
          "page": {
            "category": {
              "channel": "tvmataró",
              "channelId": "3314",
              "pageType": data.pageType,
              "primaryCategory": "",
              "topics": []
            },
            "pageInfo": {
              "language": "ca",
              "pageID": 6126,
              "programId": data.programId,
            },
            "sysInfo": {
              "sysEnv": "web"
            }
          },
          "pageInstanceID": data.pageId,
          "privacy": {
            "accessCategories": [
              {
                "categoryName": "tcf",
                "domain":  data.domain,
                "customData": {
                  "tcString": data.tcString
                }
              }
            ]
          }
        },
        "pageUrl": data.domain,
        "pageTitle": data.pageTitle,
        "deviceResolution": [
          1920,
          375
        ],
        "playerStatus": "loadedmetadata",
        "secsPlayed": data.stats.secsPlayed,
        "fullScreenStatus": data.stats.fullScreenStatus,
        "muteStatus": data.stats.muteStatus,
        "mediaType": data.mediaType,
        "mediaDuration": data.durationMedia,
        "mediaName": "tvmataró",
        "mediaUrl": data.mediaUrl,
        "mediaResolution": data.deviceResolution,
        "currentPosition": data.currentPosition,
        "bufferPercentage": data.bufferPercentage,
        "playbackRate": data.playbackRate,
        "scroll": 0,
        "ref": ""
      },
      "sidDuration":  data.sidDuration , // Duration of the session in seconda 
      "cidDuration": data.cidDuration, //Duration of the visualization of the content in seconds.
      "version": {
        "schema": "1.0.0",
        "marksCollector": {
          "type": "tvmataró web",
          "version": data.version
        }
      },
      "isActiveSession": true,
      "uid": data.userId, //Unique identifier of the user,
      "cid": data.cid, //Unique identifier of the content
      "vdid": 0,
      "did": data.did, // Unique identifier of the device.
      "sid": data.sid, // Identifier of the active session
      "cidCount": data.cidCount, // Number of times the content has been viewed
    }
  }
};

  class Konodrac {
    #player = null;
    #program = null;
    #userId = null;
    #init = new Date();
    #CI;
    #startOn = 0;
  

    #markData = {};

    constructor(parameters) {
        this.#CI      = parameters.CI;
        this.#player  = parameters.player;
        this.#program = parameters.program;
        this.#startOn = Date.now();
     
        this.#CI.logger.log('program >>>', this.#program,parameters);
    

        this.#markData.datasetid = this.#CI.settings.kndDatasetId;
        this.#markData.pageType = this.#program.type || 'LIVE';
 
        
        this.#markData.deviceResolution = [window.screen.width, window.screen.height];
        this.#markData.channelId = this.#CI.settings.bsgCanalId;
        this.#markData.tcString = (typeof this.#CI.didomiInfo !== 'undefined')? this.#CI.didomiInfo.tcString : '';
        this.#markData.pageId = document.location.href;
        this.#markData.pageTitle = document.title;
        this.#markData.domain = document.location.hostname;
        this.#markData.did = this.#CI.storage.getCookie('vneo-did');
        this.#markData.sid = this.#CI.storage.getCookie('vneo-sid');
        this.#markData.pageType = (this.#program.type === 'PROGRAM')? 'CATCHUP' : 'LIVE';
        
        this.sendMark({tag: 0});
    }

    sendMark(options) {
      var data = {...this.#markData};

      data.userId = this.#CI.user.Id;
      data.stats = this.getPlayerStadistics();

      data.mediaUrl = this.#player.currentSrc();
      data.mediaType = 'Principal'; //this.#player.currentType();
      data.playerStatus = this.#player.paused() ? 'pause' : 'play';
      data.tag = options.tag;

      let duration = (data.pageType !== 'PROGRAM')? 0:  parseInt(this.#player.duration());
      duration = (isNaN(duration))? 0 : duration;
      data.durationMedia = duration;
      data.currentPosition = parseInt(this.#player.currentTime());
      data.bufferPercentage = Math.floor(this.#player.bufferedPercent() * 100);
      data.playbackRate = this.#player.playbackRate();

      data.programId = this.#program.title

      data.cidCount = this.#CI.analytics.mediaCount;


      //sidDuration
      let sidValues  = this.#CI.storage.getCookie('vneo-sid').split('_');
      let sidDuration = parseInt( (Date.now()- parseInt(sidValues[1]) /1000));
      data.sidDuration = (!isNaN(sidDuration))? sidDuration : 1;
      data.cidDuration = parseInt ((Date.now() - this.#startOn) / 1000)

      data.cid = `${this.#program.title}_`+ this.cidFormatDate(this.#program.start) ;
      data.version = this.#CI.helpers.getApiVersion();


      let mark =  setMark(data);
      this.#CI.logger.log('mark', options.tag, mark);

      this.#CI.helpers.requestSendMark({mark});
      
    }

    getProgramData() {
      let ptogram = this.#program;
    }

    getPlayerStadistics() {       
      let player = this.#player;
      if(!player) return;
      var stats = {
        paused: player.paused(),
        duration: player.duration(),
        currentPosition: Math.floor(player.currentTime()),
        muteStatus: player.muted(),
        fullScreenStatus: player.isFullscreen(),
       // mediaType: player.currentType(),
        mediaUrl: player.currentSrc(),
        mediaResolution: [player.videoWidth(), player.videoHeight()],
        //bufferPercentage: Math.floor(player.bufferedPercent() * 100),
        playbackRate: player.playbackRate(),
        secsPlayed : (() => {
          var init = this.#init;
          var now = new Date();
          return Math.floor((now.getTime() - init.getTime()) / 1000);
        })()
   
      };
      return stats;
    }
    
    cidFormatDate (date){
    
        const day = String(date.getDate()).padStart(2, '0'); // Día con dos dígitos
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Mes con dos dígitos
        const year = date.getFullYear(); // Año
        const hours = String(date.getHours()).padStart(2, '0'); // Hora con dos dígitos
    
        return `${day}-${month}-${year}_${hours}`;
    }
   
}


 export default Konodrac;