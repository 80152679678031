
import MicroModal from 'micromodal';  // es6 module

var  modalComponent = function (vidneo, settings) {

    const parentId = settings.parentId || '#vneo_app';
        
    const template = vidneo.tmpl( `
       
            <div class="modal__overlay" tabindex="-1" data-micromodal-close>
                <div class="modal__container" role="dialog" aria-modal="true" aria-labelledby="vneo_modal-title">
                    <header class="modal__header">
                    <% if (this.title) { %>
                    <h2 class="modal__title" id="vneo_modal-title">
                        <%= this.title %>
                    </h2>
                    <% } %>
                    <button class="modal__close" aria-label="Close modal" data-micromodal-close></button>
                    </header>
                    <main class="modal__content" id="vneo_modal-content">
                    <p>
                        <%= this.content %>
                    </p>
                    </main>
                    <% if (this.footer) { %>
                    <footer class="modal__footer">
                        <%= this.footer %>
                    </footer>
                    <% } %>
                </div>
            </div>
           
    `);

    const footerTemplate = vidneo.tmpl(`
         <button class="modal__btn modal__btn-primary">Continue</button>
        <button class="modal__btn" data-micromodal-close aria-label="Close this dialog window">Close</button>
    `);
    
    
    var setComponent = () => {
        $vneo(parentId).append(
            '<div class="modal micromodal-slide" id="vneo_modal" aria-hidden="true"></div'
        );
        MicroModal.init();  

    };        
    
    
    return {
        parse : () => setComponent(),
        
    }
}


export {modalComponent};