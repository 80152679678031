export const Logger = function(verbosity,prefix) {

    /** verbosity
        0 - error
        1 - error, warn
        2 - error, warn, info
        3 - error, warn, info, log
        4 - error, warn, info, log, debug
        5 - error, warn, info, log, debug, screen
    */

    var logScreen = null;
    var lastTime = null;
    var _verbosity = verbosity || 0;
    var _prefix = prefix || "[videojs-logger] ";
    
   
    function createLogScreen(){
        if(_verbosity != 5) return;
        logScreen = document.createElement('div');
        logScreen.setAttribute("id", "logScreen");
        logScreen.setAttribute("style", "position: absolute; top: 0; left:0 ; width: 700px; height:400px; background-color: #ccc;  overflow: scroll;");
        document.body.appendChild(logScreen);
        lastTime = (new Date()).getTime();
        addLogMsg('Loger on test ....');
        
    }
    
     
    function addLogMsg(msg){
        if(_verbosity != 5) return;
        if(!logScreen) createLogScreen();
        let row = document.createElement('div');
        let now = (new Date()).getTime();
        if ( typeof msg !== 'string' )  msg = JSON.stringify(msg);
        let diffTIme = now - lastTime;
        msg += ` +${diffTIme} ms`;
        lastTime = now;
        row.innerHTML = msg;
        
        logScreen.prepend(row);
        
    }

    function setVerbosity (v)
    {
        _verbosity = v;
    }

    function handleMsg (method, args)
    {
        if ((args.length) > 0 && (typeof args[0] === 'string'))
        {
            args[0] = _prefix + args[0];
        }
        
        
        

        if (method.apply)
        {
            method.apply (console, Array.prototype.slice.call(args));
        }
        else
        {
            method (Array.prototype.slice.call(args));
        }
    }

    function debug ()
    {
        if (_verbosity < 4)
        {
            return;
        }

        if (typeof console.debug === 'undefined')
        {
            // IE 10 doesn't have a console.debug() function
            handleMsg (console.log, arguments);
        }
        else
        {
            
            handleMsg (console.debug, arguments);
        }
    }

    function log ()
    {
        if (_verbosity < 3)
        {
            return;
        }
        
        handleMsg (console.log, arguments);
    }

    function info ()
    {
        if (_verbosity < 2)
        {
            return;
        }

        handleMsg (console.info, arguments);
    }


    function warn ()
    {
        if (_verbosity < 1)
        {
            return;
        }

        handleMsg (console.warn, arguments);
    }

    function error ()
    {
        
        handleMsg (console.error, arguments);
    }
    
    
    function screen()
    {
      
       addLogMsg(arguments);
    }

    var consoleLogger = {
        setVerbosity: setVerbosity,
        debug: debug,
        log: log,
        info: info,
        warn: warn,
        error: error,
        screen: screen
    };

    if ((typeof (console) === 'undefined') || !console.log)
    {
        // no console available; make functions no-op
        consoleLogger.debug = function () {};
        consoleLogger.log = function () {};
        consoleLogger.info = function () {};
        consoleLogger.warn = function () {};
        consoleLogger.error = function () {};
    }
    

    
    return consoleLogger;


    
};

