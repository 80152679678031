
function vidneoFn(){
    return {

        require : function(name, url, callback){
            if(typeof callback !== 'function') callback = () => {};
            if(typeof window._vidneoJs == 'undefined') window._vidneoJs = {};
            if(typeof window._vidneoJs[name] != 'undefined') {
                callback();
                return;
            };
            
            window._vidneoJs[name] = {};
            
            let script = document.createElement( 'script' );
            script.id = name;
            script.onload = function () {
                callback();
            };
            script.src = url;
            document.head.appendChild(script);

        },

        remove : function(name){
            if(typeof window._vidneoJs == 'undefined') window._vidneoJs = {};
            if(typeof window._vidneoJs[name] == 'undefined') return;
            let script = document.getElementById(name);
            if(script) script.remove();
            delete window._vidneoJs[name];
        },

        async loadjQueryPromise (alias, version, integrity = null) {
            try {
                let script = document.createElement('script');
                
                // Crear una promesa que espera a que el script cargue
                await new Promise((resolve, reject) => {
                    script.onload = () => {
                        window[alias] = jQuery.noConflict(true);
                        resolve(window[alias]);
                    };
                    script.onerror = reject;  // En caso de que el script falle
                    script.src = 'https://code.jquery.com/jquery-' + version + '.min.js';
                    if (integrity) script.integrity = integrity;
                    script.crossOrigin = 'anonymous';
                    document.head.appendChild(script);
                });
                
                return window[alias];  // Devuelve el alias después de que jQuery haya sido cargado
        
            } catch (error) {
                throw new Error(`Error al cargar jQuery: ${error.message}`);
            }
            
        },

        loadCss : function (name, url) {
            if(typeof window._vidneoCss == 'undefined') window._vidneoCss = {};
            if(typeof window._vidneoCss[name] != 'undefined') return;
            
            window._vidneoCss[name] = {};
            
            let file = location.pathname.split( "/" ).pop();
            let link = document.createElement( "link" );
            link.href = `${url}`;
            link.type = "text/css";
            link.rel = "stylesheet";
            link.media = "screen,print";
            document.getElementsByTagName( "head" )[0].appendChild( link );

        } ,

        tmpl : function (txt){
            return new Function("obj","var p=[];(function(){p.push('" +
                txt.replace(/[\r\t]/g, " ")
                    .replace(/[\n]/g, "\\n")
                    //Allows @{varname} as a shortcut for <%=this.varname%>
                    .replace(/@{([\w]+)}/g,"<%=this.$1%>")
                    .split("<%").join("\t")
                    .replace(/((^|%>)[^\t]*)'/g, "$1\r")
                    // escape other single quotes
                    .split("'").join("\\'")
                    .replace(/\t=(.*?)%>/g, "',$1,'")
                    .split("\t").join("');")
                    .split("%>").join("p.push('")
                    .split("\r").join("\\'")
                + "');}).call(obj); return p.join('');");
        },
        
       


 
    }
}

const  vidneo  = vidneoFn();

export default vidneo;

