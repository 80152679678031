
import  storage from './storage';
import sha256 from 'crypto-js/sha256';
import axios from 'axios';



class Oauth {

    #oauthApi  = '';
    #clientId = '123';
    #scope = 'all';
    #redirectUri = '';
    #CI = null;
    #sessionEspiresSeconds = 3000;



    //add constructor
    constructor(parameters = {}) {
        this.#CI = parameters.ci;
        this.#oauthApi = parameters.oauthApi || '';
        this.#redirectUri = parameters.redirectUri || document.location.origin + document.location.pathname;
        this.#clientId = parameters.clientId || '123';
    }

       
    /*userLogined() {
        let userId = storage.getCookie('vneo-userId');
        return !!userId;
    }*/
   
    //funcion para crear un codigo de 8 caracteres que comine letras y numeros de forma aletoria
    _generateCode(num = 8) {
        let code = '';
        let characters = '.!-_@ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        for (let i = 0; i < num; i++) {
            code += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return code;
    }
    
    createCodeChallenge() {
        const code_challenge = this._generateCode();
        const code_challenge_verifier = sha256(code_challenge).toString();
        const payload = {
            code_challenge,
            code_challenge_verifier,
            "code_challenge_method": "sha256"
        };

        storage.setCookie('vneo-oauth_request',JSON.stringify(payload), this.#sessionEspiresSeconds);
        return payload;

    }

    async getAccessToken(code) {
        try{
            const payload = storage.getCookie('vneo-oauth_request');
            if(!payload) {
                return;
            }
            const data = JSON.parse(payload);
            const response = await axios.post(this.#oauthApi + '/oauth/token', {
                "code": code,
                "code_challenge_verifier" : data.code_challenge_verifier,
            });
            this.#CI.logger.log('>> getcookie', response);
            return response.data;
        }catch(e){
            
            return null;
        }
    }

    requestAuthorizationUri() {
        const payload  = this.createCodeChallenge();
        const redirectUri = encodeURIComponent(this.#redirectUri);
        const url = `${this.#oauthApi}/oauth/authorize?client_id=${this.#clientId}&redirect_uri=${redirectUri}&scope=${this.#scope}&response_type=code&code_challenge=${payload.code_challenge}&code_challenge_method=${payload.code_challenge_method}`;
        return url;
    }

     requestAccessCode() {
        const url = this.requestAuthorizationUri();
        window.location.href = url;
    }

    


}

export default Oauth;